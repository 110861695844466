import React, { useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import Button from 'src/components/button/button';
import { DropdownField, TextField } from 'src/components/formik-fields/formik-fields';
import InputLabel from 'src/components/input-label/input-label';
import { GET_LEAGUE_LEVEL_GROUPS } from 'src/components/leagues/leagues-queries';
import { Modal } from 'src/components/modal/modal';
import { CreateArea, CreateAreaVariables } from 'src/graphql-types/CreateArea';
import { OrganisationInput as AreaInput, OrganisationTypeEnum } from 'src/graphql-types/globalUstaTypes';
import { LeagueLevelGroups } from 'src/graphql-types/LeagueLevelGroups';
import { UpdateArea, UpdateAreaVariables } from 'src/graphql-types/UpdateArea';
import * as Yup from 'yup';

import { CREATE_AREA, GET_AREAS_COORDINATOR_COUNT, UPDATE_AREA } from './areas-queries';
import * as styles from './manage-areas.module.less';

interface AreaFormModalProps {
  show: boolean;
  parentAreaId?: string;
  areaToEdit?: AreaInput | null;
  onCancel: () => void;
  parentLevelGroupId?: string;
  onAreaCreated: () => void;
}
const HIERARCHY_MAX_DEPT_ERROR = 'Hierarchy too deep, maxDepth is 8';

const AreaFormModal: React.FC<AreaFormModalProps> = ({
  show,
  areaToEdit,
  onCancel,
  parentAreaId,
  onAreaCreated,
  parentLevelGroupId,
}) => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const { data: { competitionLevelGroups = [] } = {} } = useQuery<LeagueLevelGroups>(GET_LEAGUE_LEVEL_GROUPS, {
    client: meshGatewayClient,
    variables: { orgId },
  });

  const levelOptions = useMemo(
    () =>
      competitionLevelGroups.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [competitionLevelGroups],
  );

  const [crateArea, { loading, error, reset: resetCreateArea }] = useMutation<CreateArea, CreateAreaVariables>(
    CREATE_AREA,
    {
      client: meshGatewayClient,
      refetchQueries: [GET_AREAS_COORDINATOR_COUNT],
    },
  );
  const { message = '' } = error || {};

  const [updateArea, { loading: updateAreaLoading, reset: resetUpdateArea }] = useMutation<
    UpdateArea,
    UpdateAreaVariables
  >(UPDATE_AREA, {
    client: meshGatewayClient,
    refetchQueries: [GET_AREAS_COORDINATOR_COUNT],
    update: (cache) => cache.evict({ fieldName: 'getOrganisationByIdWithHierarchy' }),
  });

  const errorMessage = message === HIERARCHY_MAX_DEPT_ERROR ? t('hierarchy max depth') : t('generic error');
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      organisationName: Yup.string().required(t('area name required')),
      levelGroup: Yup.string().required(t('league category required')),
    });
  }, [t]);

  const handleCancel = (reset: () => void) => {
    reset();
    resetCreateArea();
    resetUpdateArea();
    onCancel();
  };

  const handleSave = (values, helpers) => {
    const { organisationId, organisationType = OrganisationTypeEnum.AREA } = areaToEdit || {};
    const { organisationName, levelGroup } = values;
    const areaData = {
      organisationName,
      organisationType,
      competition: {
        levelGroups: [levelGroup],
      },
    };

    if (areaToEdit && organisationId) {
      updateArea({
        variables: {
          organisationId,
          organisation: areaData,
        },
        onCompleted: () => {
          helpers.resetForm();
          onAreaCreated();
        },
      });
    } else {
      crateArea({
        variables: {
          organisation: {
            ...areaData,
            parentOrganisationId: parentAreaId ?? orgId,
          },
        },
        onCompleted: () => {
          helpers.resetForm();
          onAreaCreated();
        },
      });
    }
  };

  return (
    <Modal show={show} sectionClassname={styles.modalSection}>
      <h3>{areaToEdit ? t('edit area') : t('add an area')}</h3>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSave}
        initialValues={{
          organisationName: areaToEdit?.organisationName ?? '',
          levelGroup: areaToEdit?.competition?.levelGroups?.[0] ?? parentLevelGroupId ?? '',
        }}
        enableReinitialize={true}
      >
        {({ resetForm }) => {
          return (
            <Form>
              <div>
                <InputLabel className={styles.labelStyle} spacing={{ margins: { md: 'top', sm: 'bottom' } }}>
                  {t('area name')}
                </InputLabel>
                <TextField size="small" name="organisationName" placeholder={t('area name placeholder')} fullWidth />
                <InputLabel className={styles.labelStyle} spacing={{ margins: { md: 'top', sm: 'bottom' } }}>
                  {t('league category')}
                </InputLabel>
                <DropdownField
                  selectClassname={styles.leagueCategoryDropdown}
                  name="levelGroup"
                  options={levelOptions}
                  placeholder={t('league category placeholder')}
                  disabled={!!parentLevelGroupId}
                />
              </div>
              <APIErrorMessage error={error ? errorMessage : undefined} />
              <div className={styles.modalActionsWrapper}>
                <Button spacing={{ margins: { sm: 'top' } }} type="submit" loading={loading || updateAreaLoading}>
                  {areaToEdit ? t('edit area') : t('add area')}
                </Button>
                <Button
                  type="button"
                  level="secondary"
                  spacing={{ margins: { sm: 'top' } }}
                  onClick={() => handleCancel(resetForm)}
                >
                  {t('cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AreaFormModal;
